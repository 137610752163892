import Vue from 'vue'
import Router from 'vue-router'

// import Login from '@/components/Login.vue'
// import FirstLogging from '@/components/FirstLogging.vue'
// import Homepage from '@/components/Homepage.vue'
// import Records from '@/components/Records.vue'
// import Settings from '@/components/Settings.vue'
// import About from '@/components/About.vue'
// import Status from '@/components/Status.vue'
// import Summary from '@/components/Summary.vue'
// import RecordDetails from '@/components/RecordDetails.vue'
// import AddLeaving from '@/components/AddLeaving.vue'
// import ResetPassword from '@/components/ResetPassword.vue'
// import NewPassword from '@/components/NewPassword.vue'
// import SendLink from '@/components/SendLink.vue'
// import ChangePassword from '@/components/ChangePassword.vue'
// import Workers from '@/components/Workers.vue'
// import Cards from '@/components/Cards.vue'
// import WorkerDetails from '@/components/WorkerDetails.vue'
// import WorkerProjects from '@/components/WorkerProjects.vue'
// import WorkerPersonalData from '@/components/WorkerPersonalData.vue'
// import AdminPanel from '@/components/AdminPanel.vue'
// import AdminPermissions from '@/components/AdminPermissions.vue'
// import Dictionaries from '@/components/Dictionaries.vue'
// import ModuleConfiguration from '@/components/ModuleConfiguration.vue'
// import DataSharingConfiguration from '@/components/DataSharingConfiguration.vue'
// import Users from '@/components/Users.vue'
// import ChooseTenant from '@/components/ChooseTenant.vue'

const Login = () => import('@/components/Login.vue');
const FirstLogging = () => import('@/components/FirstLogging.vue');
const Homepage = () => import('@/components/Homepage.vue');
const Records = () => import('@/components/Records.vue');
const Settings = () => import('@/components/Settings.vue');
const About = () => import('@/components/About.vue');
const Status = () => import('@/components/Status.vue');
const Summary = () => import('@/components/Summary.vue');
const RecordDetails = () => import('@/components/RecordDetails.vue');
const AddLeaving = () => import('@/components/AddLeaving.vue');
const ResetPassword = () => import('@/components/ResetPassword.vue');
const NewPassword = () => import('@/components/NewPassword.vue');
const SendLink = () => import('@/components/SendLink.vue');
const ChangePassword = () => import('@/components/ChangePassword.vue');
const Workers = () => import('@/components/Workers.vue');
const ImportWorkers = () => import('@/components/ImportWorkers.vue');
const Cards = () => import('@/components/Cards.vue');
const WorkerDetails = () => import('@/components/WorkerDetails.vue');
const WorkerProjects = () => import('@/components/WorkerProjects.vue');
const WorkerPersonalData = () => import('@/components/WorkerPersonalData.vue');
const AdminPanel = () => import('@/components/AdminPanel.vue');
const AdminPermissions = () => import('@/components/AdminPermissions.vue');
const Dictionaries = () => import('@/components/Dictionaries.vue');
const ModuleConfiguration = () => import('@/components/ModuleConfiguration.vue');
const DataSharingConfiguration = () => import('@/components/DataSharingConfiguration.vue');
const Users = () => import('@/components/Users.vue');
const ChooseTenant = () => import('@/components/ChooseTenant.vue');

Vue.use(Router)


export default new Router({

    routes:[
    { path: '/', redirect: { name: 'login' } },
    { path: '/login', name: 'login', component: Login },
    { path: '/choose-tenant', name: 'chooseTenant', component: ChooseTenant },
    { path: '/account-activate/:token', name: 'firstLogging', component: FirstLogging },
    { path: '/homepage', name: 'homepage', component: Homepage },
    { path: '/timesheets', name: 'records', component: Records },
    { path: '/settings', name: 'settings', component: Settings },
    { path: '/about', name: 'about', component: About },
    { path: '/presence', name: 'status', component: Status },
    { path: '/summary', name: 'summary', component: Summary },
    { path: '/record-details', name: 'recordDetails', component: RecordDetails, props: true },
    { path: '/add-leaving', name: 'addLeaving', component: AddLeaving, props: true },
    { path: '/reset-password', name: 'resetPassword', component: ResetPassword },
    { path: '/new-password/:token', name: 'newPassword', component: NewPassword },
    { path: '/send-link', name: 'sendLink', component: SendLink },
    { path: '/change-password', name: 'changePassword', component: ChangePassword },
    { path: '/workers', name: 'workers', component: Workers },
    { path: '/import-workers', name: 'importWorkers', component: ImportWorkers },
    {
        path: '/worker-details',
        name: 'workerDetails',
        children: [
            { path: 'cards', name: 'cards', component: Cards, props: true },
            { path: 'projects', name: 'workerProjects', component: WorkerProjects, props: true },
            { path: 'personal-data', name: 'workerPersonalData', component: WorkerPersonalData, props: true }
        ],
        component: WorkerDetails
    },
    { path: '/admin', name: 'admin', component: AdminPanel },
    { path: '/admin/users', name: 'users', component: Users },
    { path: '/admin/permissions', name: 'permissions', component: AdminPermissions },
    { path: '/mdl-config/:group_id?', name: 'ModuleConfiguration', component: ModuleConfiguration },
    { path: '/ds-config/:group_id?', name: 'DataSharingConfiguration', component: DataSharingConfiguration },
    { path: '/admin/dictionaries', name: 'dictionaries', component: Dictionaries },
    ]
})