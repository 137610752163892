<template>
  <v-app>
    <Navbar
      ref="Navbar"
      v-show="navbarVisible"
      :title="title" 
      :open="false"
      :goBackLink="goBackLink"
      :goBackDisabled="goBackDisabled"
      :user="user"
      :usermail="usermail"
      :permissions="permissions"
      :tenants="tenants"
      :currentTenant="tenant"
      @set-state="setState"
      @display-error="displayError"
      @go-back-clicked="tryGoBack()"
    ></Navbar>

    <v-layout v-if="state == 'ERROR'" class="content-middler" column justify-center align-center>
      <v-icon size="100">mdi-emoticon-sad-outline</v-icon>
        <div class="title"
        style="width:90%; text-align: center; margin-top:10px;">{{ globalErrorMessage }}</div>
    </v-layout>

    <!-- Loading overlayer (covers the screen with a shadow and displays a spinner) -->
    <v-layout v-if="state == 'LOADING_OVERLAYER'"
      class="loading-overlayer"
      column
      justify-center
      align-center>
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        <div class="title" style="margin-top:10px">
          {{ loadingOverlayerMessage }}
        </div>
    </v-layout>

    <!-- Displaying error snackbar -->
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
    >
      {{ snackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-main>
      <router-view v-bind:style="{ display: routerViewDisplay}"
        @set-title="setTitle($event)" 
        @getGoBackLink="getGoBackLink($event)"
        @go-back-clicked-taken="goBackClicked=false"
        @disable-go-back="disableGoBack($event)"
        @display-error="displayError"
        @display-success="displaySuccess"
        @set-state="setState"
        @loggedIn="onLogin"
        :goBackClicked="goBackClicked"
      ></router-view>
    </v-main>
  </v-app>
</template>

<script>
// import Navbar from './components/Navbar.vue'
const Navbar = () => import('./components/Navbar.vue');

const NAVBAR_EXCLUDED_FOR = [
  "/login", "/reset-password", "/new-password/", "/sendLink", "/account-activate", "/choose-tenant"
];

export default {
  
  name: 'App',

  components: {
		Navbar
	},

  data: () => ({
    title: "",
    navbarVisible: false,
    goBackLink: "",
    goBackDisabled: false,
    goBackClicked: false,
    user: "",
    usermail: "",
    globalErrorMessage: null,
    loadingOverlayerMessage: null,
    routerViewDisplay: "block",
    state: "DEFAULT",
    permissions: null,
    tenants: null,
    tenant: null,
    snackbar: {
      visible: false,
      color: "primary",
      message: ""
    },
  }),
  computed: {
    lmessages: {
      get: function () {
        return this.$t("commons.messages");
      }
    },
  },
  mounted() {
    // if(NAVBAR_EXCLUDED_FOR.includes(this.$route.path)){
    //   this.navbarVisible = false;
    // }
    // else {
    //   this.navbarVisible = true;
    // }
    this.navbarVisible = true;
    for(let i=0;i<NAVBAR_EXCLUDED_FOR.length;i++){
      if(this.$route.path.indexOf(NAVBAR_EXCLUDED_FOR[i])!=-1){
        this.navbarVisible=false
        break;
      }
    }
  },
  beforeMount(){
    // permissions
    if(localStorage.getItem('permissions') !== null){
      this.permissions = JSON.parse(localStorage.permissions);
    }
    // locale (set to English by default)
    this.$i18n.locale = 'en';
    if (this.$cookies) {
      let language = this.$cookies.get('language');
      if(language != null && language !== 'undefined'){
        this.$i18n.locale = language;
      }
    }    
	},
  methods: {
    setTitle(title){
      this.title=title
    },
    getGoBackLink(link){
      this.goBackLink=link
    },
    tryGoBack(){
      this.goBackClicked=true
    },
    disableGoBack(value){
      this.goBackDisabled=value
    },
    setPermissions(permissionsList){
      localStorage.permissions = JSON.stringify(permissionsList);
      this.permissions = permissionsList;
    },
    setTenants(tenants){
      let stringifiedTenants = []
      if(tenants){
        for(let item of tenants){
          stringifiedTenants.push(JSON.stringify(item))
        }
      }
      localStorage.setItem('tenants', JSON.stringify(stringifiedTenants))
      this.tenants=tenants
    },
    onLogin(permissionsList, username, usermail, tenants){
      this.setPermissions(permissionsList);
      this.setTenants(tenants)
      this.user=username
      this.usermail=usermail
      this.tenant={
        name: localStorage.tenantName,
        slug: localStorage.tenantSlug
      }


      var that = this;
      this.$nextTick(function(){
        console.log(that.$refs);
        that.$refs.Navbar.redirectDefault();
      });
    },
    displayError(errorMessage){
      this.snackbar.color="error"
      this.snackbar.message=errorMessage
      this.snackbar.visible=true
    },
    displaySuccess(message){
      this.snackbar.color="success"
      this.snackbar.message=message
      this.snackbar.visible=true
    },
    removeErrorDialog(dialogId) {
                for (var i = 0; i < this.errorDialogs.length; i++) {
                    if (this.errorDialogs[i].id == dialogId) {
                        setTimeout(() => {
                            this.errorDialogs.splice(i, 1);
                        }, 200);
                        break;
                    }
                }
            },
    setState(newState, additionalParams = null) {
                this.state = newState.toUpperCase();
                var isDefault = false;

                switch (this.state) {
                    // case "LOADING":
                    //     this.loadingMessage =
                    //         typeof additionalParams === "undefined" || additionalParams == null
                    //             ? this.lmessages.loading
                    //             : additionalParams;
                    //     break;
                    case "LOADING_OVERLAYER":
                        this.loadingOverlayerMessage =
                            typeof additionalParams === "undefined" || additionalParams == null
                                ? this.lmessages.loading
                                : additionalParams;
                        isDefault = true;
                        break;
                    // case "PROGRESS_OVERLAYER":
                    //     if (
                    //         typeof additionalParams === "undefined" ||
                    //         additionalParams == null
                    //     ) {
                    //         this.progressOverlayerMessage = this.lmessages.loading;
                    //         this.progressBar = 0;
                    //     } else {
                    //         if (Array.isArray(additionalParams)) {
                    //             this.progressOverlayerMessage = additionalParams[0];
                    //             this.progressBar = additionalParams[1];
                    //         } else if (typeof additionalParams === "object") {
                    //             this.progressOverlayerMessage = additionalParams.message;
                    //             this.progressBar = additionalParams.progress;
                    //         } else {
                    //             this.progressOverlayerMessage = additionalParams;
                    //             this.progressBar = 0;
                    //         }
                    //     }
                    //     isDefault = true;
                    //     break;
                    case "ERROR":
                        this.globalErrorMessage =
                            typeof additionalParams === "undefined" || additionalParams == null
                                ? this.lmessages.errorOccured
                                : additionalParams;
                        break;
                    default:
                        this.state = "DEFAULT";
                        isDefault = true;
                }

                if (isDefault) this.routerViewDisplay = "block";
                else this.routerViewDisplay = "none";
            },
            
  },
  watch: {
    $route: function(newPath){
      if(NAVBAR_EXCLUDED_FOR.includes(newPath.path)){
        this.navbarVisible = false;
      }
      else {
        this.navbarVisible = true;
      }
    }
  }
};
</script>

<style>

/* .main{
  margin-top: 35px;
} */

.main{
  padding-top: 70px;
}

.loading-overlayer {
  width: 100%;
  height: 100%;
  z-index: 300;
  color: #fff;
  position: fixed;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.64);
}

</style>
