export default {
    app: {
        navbar: {
            emailUnassinged: "E-Mail unassigned",
            about: "About",
            logout: "Log out",
            settings: "Settings",
            workers: "Workers",
            companies: "Companies",
            administration: "Administration",
            dictionaries: "Dictionaries",
            permissions: "Permissions",
            timesheets: "Timesheets",
            currentPresence: "Current Presence",
            users: "Users",
            unknown: "Unknown",
        },
        pageNotFoundDetails: "Requested page does not exist or has been removed. Your application has been redirected to the default main page."
    },
    views: {
        commons: {
            alertWithDetails: {
                details: "Details"
            },
            errorDialog: {
                copyError: "Copy error",
                copied: "Copied",
                failedToCopy: "Failed to copy"
            },
            exportDialog: {
                exportWorkers: "Export workers",
                exportTimesheets: "Export timesheets",
                allVisible: "All visible workers",
                selectedOnly: "Selected workers only",
                chooseTemplate: "Choose template",
                workerData: "Worker data",
                weekly: "Weekly",
                weeklySchedule: "Weekly work schedule",
                monthly: "Monthly",
                monthlySchedule: "Monthly work schedule",
                selectWeek: "Select week",
                selectMonth: "Select month",
                selectRange: "Select date range",
            },
            editRecordDialog: {
                edit: "Edit",
                selectProject: "Select project",
                entering: "Entering",
                leaving: "Leaving",
                setAs: "Set as",
                recordRequired: "Required at least one new record",
                hourRequired: "Select hour for all records",

            },
            removeRecordDialog: {
                message: "Are you sure you want to delete",
            },
            // confirmBoardingDialog: {
            //     title: "Confirmation of employment",
            //     chooseProjects: "Choose projects",
            //     startDate: "Begin date",
            //     checkbox1: "Signed contract",
            //     checkbox2: "Complete questionnaire",
            //     checkbox3: "Medical report and facture",
            //     checkbox4: "Certificate of no criminal record",

            //     uploading: "Uploading",
            //     tooManyWorkersForThisAction: "Too many workers selected for this action",
            // },
            // resignDialog: {
            //     title: "Employee's resignation",
            //     pickProjects: "Pick projects",
            //     resignDate: "Resignation date",
            //     offBoardingDate: "Off-boarding date",
            //     contractEndDate: "Contract end date",
            //     projectEndDate: "Project end date",
            //     chooseProjects: "Choose project",
            //     reason: "Reason",
            //     selectForResignReasons: [
            //         "Not taking a job",
            //         "Job abandoned",
            //         "Amicable settlement",
            //         "Disciplinary dismissal",
            //         "Contract concluded",
            //         "Contract concluded with distinction"
            //     ],
            //     ratingComment: "Rating comment",
            //     errorOccured: "Error occured",
            //     errorMessage: "Error occured while processing data for boarding with id: ",
            //     errorCodeShort: "RSGND#5",
            //     errorCodeLong: "REQUEST_FAILED"
            // },
            // removeBoardingDialog: {
            //     title: "Remove boardings",
            //     chooseProjects: "Choose projects"
            // },
            // changeBoardingPlansDialog: {
            //     title: "Change of plans",
            //     chooseProject: "Choose projects",
            //     chooseWorker: "Choose employee",
            //     changeManager: "Change manager",
            //     changeDate: "New date",
            //     reason: "Reason",
            //     errorOccured: "Error occured",
            //     errorMessage: "Error occured while processing data for employee with id: ",
            //     errorCodeShort: "CHNGBRD#1",
            //     errorCodeLong: "REQUEST_FAILED"
            // },
            // changeEmployeePlansDialog: {
            //     title: "Change of plans",
            //     changeLocation: "New location",
            //     changeManager: "New manager",
            //     reason: "Reason"
            // },
            // sendEmployeeMessageDialog: {
            //     title: "Short message",
            //     cancel: "Cancel",
            //     send: "Send"
            // },
            // offBoardingDialog: {
            //     title: "Employee's resignation",
            //     pickProjects: "Pick projects",
            //     offBoardingDate: "Off-boarding date",
            //     reason: "Reason",
            //     ratingComment: "Rating comment",
            //     errorNoProjectsAssignedTitle: "No projects assigned",
            //     errorNoProjectsAssignedMessage: "No projects were provided for this dialog",
            //     errorOccured: "Error occured",
            //     errorMessage: "Error occured while processing data for boarding with id: ",
            //     errorCodeLong: "REQUEST_FAILED"
            // },
            // attachPicturesDialog: {
            //     defaultTitle: "Attach images",
            //     attachFromCamera: "Take a photo",
            //     attachFromFiles: "Attach a file",
            //     noFilesAttached: "No files attached",
            //     tooManyFilesTitle: "Too many files",
            //     tooManyFilesText: "You are trying too attach {numberOfFiles} files. Maximum number of files is {maxNumberOfFiles}.",
            //     totalFilesSizeTooBigTitle: "Total size limit exceeded",
            //     totalFilesSizeTooBigText: "The files you're trying to attach are too big. The maximum total size is {maxAttachmentsSize}.",
            //     fileTooBigTitle: "Files exceeded size limit",
            //     fileTooBigText: "These files are too big and exceed the size limit ({sizeLimit})",
            // },
            // postalAddressEditDialog: {
            //     defaultHeadline: "Edit address",
            //     dictionaryLocation: "Description",
            //     streetName: "Street name",
            //     streetNumber: "Street number",
            //     propertyNumber: "Property number",
            //     zipCode: "Zip code",
            //     city: "City",
            //     country: "Country",

            //     invalidLocation: "Invalid location",
            //     missingLocationErrorTitle: "Unknown location",
            //     missingLocationError: "Picked location could not be found in locations dictionary",
            // },
            // extendOffboardingDialog: {
            //     defaultHeadline: "Extend Off-Boarding",
            //     chooseProjects: "Choose projects",
            //     pickNewOffBoardingDate: "Pick new off-boarding date"
            // },
            // nullableRating: {
            //     rating: "Rating",
            //     none: "None"
            // }
        },
        login: {
            username: "Username",
            password: "Password",
            login: "Log in",
            forgotPassword: "Forgot password?",
            invalidLoginOrPassword: "Invalid username or password",
            accountNotActivated: "Account has not been activated",
            loginMethodBlockedByAdministrator: "The login method has been disabled by the administrator",
            invalidAccountConfig: "Account configuration invalid, contact the administrator.",
            accountDisabled: "Account has been temporarly disabled. Contact the administrator.",
            multipleAccountsAssignedToEmail: "Cannot login via E-Mail address. This E-Mail address is assigned to multiple accounts",
            unassignedUserProfile: "User profile unassigned. Contact the administrator",
            unassignedWorker: "Improperly configured account. Contact the administrator",
            couldNotLoginNoPermissions: "You don't have any permissions. Contact the administrator",
            error429message: "Too many requests with an unauthorized connection. Please wait a moment before trying again.",

            unknownError: "An unknown error has occured. Please try again later.",
            missingInput: "Please provide username and password",
            infoRegulations: "Terms of use",
            infoPrivacy: "Privacy policy",
            infoHelp: "Help",

            selectedLanguage: "English",
            language: "Language",
            languageSelect: [
                "Polish",
                "English",
                "German"
            ],
            languageDictionary: [{
                    language: "Polish",
                    key: "pl"
                },
                {
                    language: "English",
                    key: "en"
                },
                {
                    language: "German",
                    key: "de"
                }
            ]
        },
        passwordReset: {
            yourEmail: "Your E-Mail",
            birthDate: "Date of birth",
            resetPassword: "Reset password",
            cancelBack: "Cancel / Back",
            okBack: "OK / Back",
            needHelp: "Need help?",
            linkSent: "We've sent a link you can use to reset your password. Check your mail.",
            couldNotResetPassword: "Incorrect email.",
            sendMailError: "Send mail error. Try again in a couple of minutes.",
            emailNotProvided: "Email was not provided",
            birthDateNotProvided: "Birth date was not provided",
            incorrectEmailStructure: "Incorrect email structure",
        },
        accountActivation: {
            title: "User account activation",
            politicsAgreement1: "I have read and agree to the privacy policy",
            politicsAgreement2: "I have read and agree to the terms of service provision",
            markAgreementsToContinue: "Mark the agreements in order to use the application",
            activatingAccount: "Activating the account...",
            activationFailed: "Account activation failed",
            repeatPassword: "Repeat password",
            password: "Password",
            activationLinkOutdated: "Your link is outdated. Please generate a new link and check your mail in order to use it.",
            birthDate: "Birth date",
            label1: "1. Set password for your account",
            descriptionLabel1: "In order to set your password, enter date of your birth and enter password twice. Password must contain at least 8 characters (including at least 1 capital letter, 1 digit and 1 special character).",
            label2: "2. Accept terms and conditions",
            birthDateRequired: "Date of birth is required",
            invalidPasswordStructure: "Password must contain at least 8 characters (including at least 1 capital letter, 1 digit and 1 special character).",
            incorrectlyRepeatedPassword: "Not matching passwords",
            incorrectBirthDate: "Incorrect date of birth. You have %d possible attempts left",
            errorTitle: "Error occurred",
            error500message: "An internal server error occured. Please contact the adminsitrator.",
            error404message: "No user account to activated found - invalid activation link",
            error403message1: "Could not activate account - please try again later",
            error403message2: "Could not activate account that has already been activated",
            error400message1: "Could not activate account - incorrect data",
            error400message2: "Account activation attempts exceeded, please try again later",
            error429message: "Too many requests with an unauthorized connection. Please wait a moment before trying again.",
            error400accountActivate: "Account is already active",
            error400accountBlocked: "Account is blocked",
            error400: "Account activation failed",
            resendActivationLink: "Generate new activation link",
            couldNotSendNewEmail: "Could not send new activation link",
            newActivationLinkSent: "New activation link sent, check your mailbox",
            birthDateNotProvided: "Birth date was not provided",
            tryAgainInAnHour: "Your account has been blocked for an hour. Try again later.",
            gotoLoginPage: "Go to login page",
            activationSuccess: "Account activated successfully!"
        },
        about: {
            pageTitle: "About Fast OnBoarding",
            header: "YOUR IT ENGINE FOR BUSINESS",
            version: "Version",
            privacyPolicy: "Privacy policy",
            learnMore: "Learn more",
            contact: "Contact us / Request help"
        },
        qr: {
            pageTitle: "My QR code",
        },
        timesheets: {
            pageTitle: "Timesheets",
            weekDays: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
            project: "Project",
            searchWorker: "Search worker",
            all: "All",
            selected: "Selected",
            summary: "Summary",
            missingEntering: "missing entering",
            missingLeaving: "missing leaving",
            leaveDuringWork: "leave during work",
            worktimeOver12: "worktime over 12h",
            undefinedRecord: "Undefined record",
            itemsPerPage: "Items per page",
            of: "of",
            elements: "elements",
            since: "since",
            to: "to",

        },
        recordDetails: {
            since: "Since",
            to: "To",
            totalWorktime: "Total worktime",
            projects: "Work on projects",
            addRecords: "ADD RECORDS",
            editRecords: "EDIT RECORDS"
        },
        currentPresence: {
            pageTitle: "Current presence",
            projects: "Projects",
            present: "PRESENT",
            absent: "ABSENT",
            unknownStatus: "UNKNOWN STATUS",
            entry: "entry",
            leave: "leave",
            unknown: "unknown",
            planned: "planned",
            yesterday: "yesterday",
            nextDay: "next day",
            edit: "Edit",
            addLeaving: "Add leaving",
            addEntering: "Add entering",
            all: "All",

        },

        userProfile: {
            myProfile: "My profile",
            workerProfile: "Profile",
            inSince: "in Fast OnBoarding since {since}",
            unassigned: "Unassigned",
            permanentContract: "For an indefinite period",
            unassignedProject: "Unassigned",
            sinceDateToDate: "since {since} to {to}",
            sinceDate: "since {since}",

            editRating: "Edit rating",
            ratingComment: "Rating comment",

            contact: "Contact",
            privatePhone: "Private phone number",
            businessPhone: "Business phone number",
            chooseCountry: "Choose country",
            privateMail: "Private mail",
            businessMail: "Business mail",

            personalDetails: "Personal details",
            birthDate: "Date of birth",
            personalAddress: "Address",
            pesel: "Pesel",
            drivingLicense: "Driving license",
            contactAddressInformation: "Contact address information",
            additionalInformation: "Additional information",
            otherData: "Other data",
            noData: "No data",

            contractDetails: "Contract details",
            beginDate: "Begin date",
            endDate: "End date",

            stayDetails: "Details of stay",
            stayAddress: "Address of stay",

            contractEndDateQuestionTitle: "Extend contract",
            contractEndDateQuestionContent: "Please confirm extending employee's contract from {oldDate} to {newDate}",

            age: "years old",
            edit: "Edit",
            close: "Close",
            seeMoreProjects: "See more projects",
            projectsList: "Projects list",

            location: {
                streetName: "Street name",
                streetNumber: "Street number",
                propertyNumber: "Property number",
                zipCode: "Zip code",
                city: "City",
                country: "Country"
            },
            lackOfContactPostalAddress: "No data - choose your address",

            countriesSelect: [{
                    key: "AL",
                    text: "Albania"
                },
                {
                    key: "AD",
                    text: "Andorra"
                },
                {
                    key: "AT",
                    text: "Austria"
                },
                {
                    key: "BE",
                    text: "Belgium"
                },
                {
                    key: "BY",
                    text: "Belarus"
                },
                {
                    key: "BA",
                    text: "Bosnia and Herzegovina"
                },
                {
                    key: "BG",
                    text: "Bulgaria"
                },
                {
                    key: "HR",
                    text: "Croatia"
                },
                {
                    key: "ME",
                    text: "Montenegro"
                },
                {
                    key: "CZ",
                    text: "Czech Republic"
                },
                {
                    key: "DK",
                    text: "Denmark"
                },
                {
                    key: "EE",
                    text: "Estonia"
                },
                {
                    key: "FI",
                    text: "Finland"
                },
                {
                    key: "FR",
                    text: "France"
                },
                {
                    key: "GR",
                    text: "Greece"
                },
                {
                    key: "ES",
                    text: "Spain"
                },
                {
                    key: "NL",
                    text: "Netherlands"
                },
                {
                    key: "IE",
                    text: "Ireland"
                },
                {
                    key: "IS",
                    text: "Iceland"
                },
                {
                    key: "KZ",
                    text: "Kazakhstan"
                },
                {
                    key: "LI",
                    text: "Liechtenstein"
                },
                {
                    key: "LT",
                    text: "Lithuania"
                },
                {
                    key: "LV",
                    text: "Latvia"
                },
                {
                    key: "MK",
                    text: "Macedonia"
                },
                {
                    key: "MT",
                    text: "Malta"
                },
                {
                    key: "MD",
                    text: "Moldova"
                },
                {
                    key: "MC",
                    text: "Monaco"
                },
                {
                    key: "DE",
                    text: "Germany"
                },
                {
                    key: "NO",
                    text: "Norway"
                },
                {
                    key: "PL",
                    text: "Poland"
                },
                {
                    key: "PT",
                    text: "Portugal"
                },
                {
                    key: "RU",
                    text: "Russia"
                },
                {
                    key: "RO",
                    text: "Romania"
                },
                {
                    key: "SM",
                    text: "San Marino"
                },
                {
                    key: "XS",
                    text: "Serbia"
                },
                {
                    key: "SK",
                    text: "Slovakia"
                },
                {
                    key: "SI",
                    text: "Slovenia"
                },
                {
                    key: "CH",
                    text: "Switzerland"
                },
                {
                    key: "SE",
                    text: "Sweden"
                },
                {
                    key: "TR",
                    text: "Turkey"
                },
                {
                    key: "UA",
                    text: "Ukraine"
                },
                {
                    key: "HU",
                    text: "Hungary"
                },
                {
                    key: "GB",
                    text: "United Kingdom"
                },
                {
                    key: "IT",
                    text: "Italy"
                },
                {
                    key: "XK",
                    text: "Kosovo"
                }
            ],

            youDoNotHavePriviledgesToThisView: "You do not have priviledges to this view",
        },
        documents: {
            title: "Documents",
            myDocumentsTitle: "My documents",
            correctUploadInfo: "Photos uploaded",
            errorUploadInfo: "There was a problem within uploading photos",
            attachPicturesInfo: "Upload photos",
            noDocuments: "No documents found",
            noMatchingDocumentsFound: "No matching documents found",
            couldNotDownloadDocument: "Could not display document",
            search: "Search",
            groupBy: "Group by",
            sortBy: "Sort by",
            name: "Name",
            date: "Date",
            download: "Download",
            fromMostRecent: "From most recent",
            fromLeastRecent: "From least recent",
            alphabeticalAscending: "Alphabetical A-Z",
            alphabeticalDescending: "Alphabetical Z-A",
            dontGroup: "Don't group",
            byType: "Type",
            byDate: "Date",
            lastWeek: "Last week",
            lastMonth: "Last month",
            lastYear: "Last year",
            elder: "Older",
            downloadingDocuments: "Documents downloading",
            areYouSureYouWantDownloadAllDocuments: "Are you sure you want download all documents",
            cancel: "Cancel",

            workersDocuments: "Workers' documents",
            workerFullName: "Worker fullname",
            workerDateBirth: "Worker birth date",
            documentCreatedAt: "Created at",
            documentTypeShort: "Type",
            documentTypeLong: "Document type",
            documentType: "Type",
            documentName: "Document name",
            allTypesSelected: "All",
            chooseDataTableColumns: "Choose table columns",
            modify: "Modify",
            preview: "Preview",

            save: "Save",
            changeDocumentName: "Change document name",
            changeDocumentType: "Change document type",
            updateDocumentFile: "Replace existing document",

            update: "Replace existing",
            changeType: "Change type",
            chooseNewFile: "Choose new file",
            lackOfPermission: "You are not allowed to be here",
            incorrectRequest: "Incorrect request data",

            recordNotModified: "Document not updated",
            recordUpdatedSuccessfully: "Document updated",
            lackOfPermissionToUpdateDocument: "You are not allowed to update any document",

            couldNotAddNewDocument: "Could not attach given document",
            documentAddedSuccessfully: "Document attached successfully",
            addNewDocument: "Attach new document",
            chooseWorker: "Choose worker",
            startWritingToFind: "Write something to search",
            overrideExistingFiles: "Override existing files",
            uploadingInProgress: "Uploading in progress...",
            sorryWeCouldntConnectToServer: "Sorry, we could not connect to server",
            couldNotDisplayDocument: "Could not display document",
            importDocuments: "Import documents",
            rowsPerPage: "Rows per page",
            of: "of",

            delete: "Delete",
            deleteDocument: "Delete",
            areYouSureYouWantToDeleteThisDocument: "Are you sure you want to delete this document?",
            assignedTo: "Assigned to",
            birthDatePrefix: "born on",

            unassigned: "Unassigned",
            duplicateFilenameTitle: "File with provided name already exist",
            proposedFilename: "Proposed filename",
            ok: "OK",
        },
        projects: {
            title: "Projects",
            states: [{
                    name: "Hired",
                    stateKey: "A"
                },
                {
                    name: "Planned",
                    stateKey: "P",
                },
                {
                    name: "Historical",
                    stateKey: "H",
                }
            ],

            selectItems: [
                "Hired",
                "Historical",
                "Planned",
            ],
            addProject: "Add project",
            project: "Project",
            type: "Type",
            assignmentStartDate: "Assignment Start Date",
            onBoardingDate: "On-boarding date",
            cancel: "Cancel",
            confirm: "Confirm",
            confirmEmployment: "Confirm employment",
            assuranceEmployment: "Do you want to confirm employment of this employee on that project?",
            no: "No",
            yes: "Yes",
            removeBoarding: "Remove boarding",
            assuranceRemovement: "Do you want to remove this boarding from the employee? It will completely disappear, as if it was never here",
            employeeResignation: "Employee resignation",
            changeProject: "Change project",
            offBoardingDate: "Off-boarding date",
            terminateEmployment: "Terminate employment",
            contractEndDate: "Contract end date",
            projectEndDate: "Project end date",
            editBoarding: "Edit boarding",
            contractStartDate: "Contract start date",
            projectStartDate: "Project start date",
            unassigned: "Unassigned",
            hired: "Hired",
            historical: "Historical",
            planned: "Planned",
            fulltime: "Full-Time",
            parttime: "Part-Time",
            timebased: "Time-Based",
            fixedprice: "Fixed-Price",
            saving: "Saving",
            errorOccuredTitle: "Error",
            errorOccured: "An error occured. That's all we know.",
            cannotAddProjectTitle: "Cannot add project",
            cannotAddProjectMessage: "Cannot add any projects to this worker",
            sendingRequest: "Wysyłam zapytanie",
            requestError: "Sending request",
            requestErrorMessage: "An error occured while sending the request",
            noDate: "No date",
            cannotLoadPageTitle: "Could not load page",
            cannotLoadPageMessage: "There was an error while attempting to load the page",
            noInformation: "There is no information",

            projectAdded: "Project added",
            projectUpdated: "Project updated",
            projectRemoved: "Project removed",
            changeWorkType: "Change work type",
            details: "Project details",
            close: "Close",
        },
        settings: {
            title: "Settings",
            settings: {
                preferences: {
                    title: "Preferences",
                    description: "Change language, manage notifications"
                },
                changePassword: {
                    title: "Security",
                    description: "Change password"
                }
            }
        },
        changePassword: {
            title: "Change password",
            headerPrefix: "Password for",
            pageContent: "In order to set new password, you have to enter old password, then you provide new password twice. New password must contain at least 8 letters (including at least 1 capital letter and 1 number or special sign).",
            cancel: "Cancel",
            saveChanges: "Save changes",
            oldPassword: "Old password",
            newPassword: "New password",
            repeatPassword: "Repeat password",
            updatedSuccessfully: "Updated successfully",
            couldNotUpdate: "Could not update password",
            updatingInProgress: "Password updating in progress...",
            oldPasswordNotProvided: "Current password was not provided",
            invalidPasswordStructure: "Password must contain at least 8 characters (including at least 1 capital letter, 1 digit or special character).",
            incorrectlyRepeatedPassword: "Not matching passwords",
            incorrectPassword: "Incorrect password",
            newPasswordNotProvided: "New password was not provided",
            newPasswordCannotBeTheSameAsOldOne: "New password cannot be the same as old one",
        },
        pdfViewer: {
            couldNotDisplayDocument: "Could not display document",
            couldNotDownloadDocument: "Could not download the document. Please try again."
        },
        settingPassword: {
            title: "Password setting",
            headerPrefix: "Set password for your account",
            pageContent: "Your new password must contain at least 8 letters (including at least 1 capital letter and 1 number and 1 special sign).",
            saveChanges: "Save changes",
            newPassword: "New password",
            repeatPassword: "Repeat password",
            updatedSuccessfully: "Updated successfully",
            couldNotUpdate: "Could not update password",
            updatingInProgress: "Password updating in progress...",
            passwordNotProvided: "Password not provided",
            birthDateNotProvided: "Birth date was not provided",
            invalidPasswordStructure: "Password must contain at least 8 characters (including at least 1 capital letter, 1 digit or special character).",
            incorrectlyRepeatedPassword: "Not matching passwords",
            incorrectPassword: "Incorrect password",
            globalErrorMessage: "Sorry, an error occurred",
            invalidToken: "Invalid reset link",
            birthDate: "Birth date",
            linkOutdated: "Reset link has expired",
            linkAlreadyUsed: "Reset link has already been used",
            incorrectBirthDate: "Invalid birth date. You have %d attempts left",
            incorrectBirthDateOneLeft: "Invalid birth date. You have %d attempt left",
            tryAgainChangePasswordLaterTitle: "Hold on",
            tryAgainChangePasswordLaterMessage: "Limit of password reset attempts exceeded - try again later.",
        },
        importWorkers: {
            title: "Import workers",
            networkError: "Network error. Please check your connection.",
            beforeUpload: {
                userInfoPrefix: "Warning!",
                userInfoWithoutLinkPart: "Attached file should be comply with ",
                userInfoLink: "template",
                attachmentsPanelHeader: "Load XLS file with workers data",
                loadFiles: "Load",
                filesSizeInfo: "Max. file size : 50MB",
                importTemplateStructure: "Template structure",
                excelStructure: "Excel file structure",
                sampleZipContent: "Sample zip file content",
                fileNameDescription: "{L Jeżeli nazwa techniczna pliku nie będzie posiadała rozszerzenia automatycznie zostanie dodane rozszerzenie .pfd. Jeżeli nazwa docelowa pliku będzie pusta to dokument automatycznie przyjmnie taka sama nazwę jak plik wejściowy. Jeżeli nazwa docelowa pliku nie będzie zawierać rozszerzenia automatycznie zostanie dopisane takie jakie posiada dokument wejściowy. }",
            },
            download: "Download",
            omitHeadersOption: "Omit headers",
            overrideExistingFiles: "Override existing files",
            uploadWithDocumentTypeOption: "Import documents including their type",
            otherDocumentType: "Other",
            validatedZip: {
                uploadedZip: "Uploaded file: ",
                workersToAdd: {
                    label: "Workers to add: ",
                    suffixSingular: "worker",
                    suffixPlural: "workers"
                },
                selectFilesStatuses: {
                    label: "File status",
                    ok: "OK",
                    warning: "With warning",
                    error: "With error"
                },
                filtersHeader: "Uploading parameters",
                tableHeaders: {
                    id: "Nr",
                    lastName: "Lastname",
                    firstName: "Firstname",
                    externalID: "External ID",
                    projects: "Projects",
                    email: "Email",
                    cards: "Cards",
                    tags: "Tags",
                    status: "Status",
                    comment: "Comment",
                    importStatus: "Import status",
                    importComment: "Import comment",
                    action: "Action",
                    documentType: "Document type",
                },
                cancelHeader: "Cancel",
                addWorkersButtonLabel: "Upload workers",
                uploadWithoutErrorsMessage: "Warning! Workers with errors will be omitted. }",
                goBack: "Go back",
                search: "Search"
            },
            validation: {
                overlayerMessage: "Files validation in progress",
                excelLackMessage: "Could not find any *.xlsx file in uploaded zip",
                tooLargeExcelLoadedMessage: "Excel file too large, max. size of uploaded file is 50MB",
                invalidZipStructure: "Could not process ZIP, invalid structure - could not find 'import.xlsx' file",
                correctFiles: "Correct ZIP file and upload again",
                goBack: "Go back",
                pageZipErrorMessageHeader: "An error occurred while processing zip",
                fileIsNotPresentInExcel: "File not found in given excel",
                invalidDocTypeWarningMessage: "Invalid document type : {typeInExcel} - default status ('{defaultType}') was assigned.",
                unknownUserIncorrectPesel: "Unknown worker - it's likely that incorrect PESEL number was provided",
                errorOccurredWhileReadingInputFilename: "An error occurred while reading input filename - it is likely that it was not provided",
                errorOccurredWhileReadingOutputFilename: "An error occurred while reading output filename - it is likely that it was not provided",
                duplicateFilename: "Worker document duplicated",
                fileWillBeOverwritten: "Worker document will be overwritten",
                nonExistentProjects: "Non-existent projects",
                nonExistentTags: "Non-existent tags",
                cardAssigned: "Cards already assigned",
                unique: "Personal data with this id already exists",
                fieldCannotBeNull: "Required fields"
            },
            uploadingFiles: "Uploading files",
            lackOfPermission: "You are not allowed to be here",
            couldNotProcessAllSentFiles: "Could not process all sent files",
            filename: "File name",
            errorContent: "Error message",
            workersUploadedCorrectly: "Successfully added {0} workers",
            filesUploadedButSomeErrorsOccurred: "Files were processed - in some cases errors occurred while saving documents",
            inputFilenameNotProvided: "Input file information not provided",
            fileNotFoundInZip: "Could not find input file in attached zip file",

            importSummaryHeaders: "Import summary",
            importWorkersNumber: "Workers to import",
            excludedWorkersNumber: "Excluded from import",
            errorWorkersNumber: "Workers with error",
            warningDocumentsNumber: "Warnings",

            invalidInputFileGlobalMessage: "Uploaded file is not a *.zip package",

            rowError: "First 100 rows are incorrect. Check and correct the excel file, then try again.",
            defaultDocumentType: "Default document type",

            uploadBarPreparingRequest: "Preparing request...",
            uploadBarUploading: "Uploading files...",
            uploadBarProcessing: "Processing files...",
            uploadBarComplete: "Import complete",
            couldNotDownloadSummaryFile: "Could not download import summary file",
            downloadImportSummary: "Download summary file",

            active: "Active",
            inactive: "Inactive",
            unknown: "Unknown",
        },
        adminPanel: {
            adminPanelTitle: "Administration panel",
            addUsersNotification: "Add users to your system!",
            addUsers: "Add users here",
            activeUsers: "ACTIVE USERS",
            administrators: "Administrators",
            usersPanel: "Users",
            activeWorkers: "WORKERS",
            workers: "Workers",
            activeDocuments: "DOCUMENTS",
            documentsSize: "Size",
            officeData: "Company data",
            officeDataDescription: "Update your company data",
            settlement: "Settlements",
            settlementDescription: "Check your settlements and manage licenses",
            dictionaries: "Dictionaries",
            dictionariesDescription: "Define your own types and statuses",
            permissions: "Permissions",
            permissionsDescription: "Manage modules permissions",
            users: "Users",
            usersDescription: "Add or manage users",
            youDoNotHavePriviledgesToThisView: "You do not have priviledges to this view",
            addresses: "Addresses",
            addressesDescription: "Define your own addresses",
        },
        addUserPanel: {
            addUserTitle: "Add user",
            basicDataSection: "BASIC DATA",
            surname: "Last name",
            name: "First name",
            email: "E-mail",
            phonenumber: "Phone number",
            personalDataSection: "PERSONAL DATA",
            birthDate: "Birth date",
            pesel: "PESEL",
            securitySection: "SECURITY",
            role: "Role",
            loginSection: "USERNAME",
            login: "Unique username",
            cancel: "Cancel",
            submit: "Save",
            administrator: "Administrator",
            hrManager: "HR Manager",
            projectManager: "Project Manager",
            manager: "Manager",
            sendEmail: "Send Email to activate account",
            dataAccess: "Data Access",
            allRecords: "All records",
            myRecords: "My records",
            myProjectRecords: "All records in my projects",
            lastNameRequired: "Last name is required",
            lastNameCharakters: "Last name must have less than 50 characters",
            firstNameRequired: "First name is required",
            firstNameCharakters: "First name must have less than 30 characters",
            emailRequired: "Email is required",
            emailValid: "Invalid Email",
            roleRequired: "Role is required",
            dataAccessRequired: "Data access is required",
            loginRequired: "Login is required",
            errorInvalidUserFirstName: "Invalid First name",
            errorInvalidUserLastName: "Invalid Last name",
            errorInvalidUserBirthDate: "Invalid birth date",
            errorInvalidUserEmail: "Invalid email",
            errorInvalidUserPhonenumber: "Invalid phone number",
            errorInvalidUserPesel: "Invalid pesel",
            errorInvalidUserRole: "Role is required",
            errorInvalidUserDataAccess: "Data access is required",
            errorInvalidUserLogin: "Invalid login",
            adding: "Adding user",
            errorAddUser: "Unexpected error has occured. Please contact server administrator. ",
            addUserSuccess: "User has been added!",
            errorInvalidFields: "Some fields are invalid.",

            youDoNotHavePriviledgesToThisView: "You do not have priviledges to this view",
        },
        configurationPanel: {
            configurationTitle: "Dictionaries",
            documentsSection: "Documents",
            addDocumentType: "Add document type",
            add: "Add",
            documentTypes: "Created document types",
            name: "Name",
            action: "Action",
            deactivate: "Deactivate",
            activate: "Activate",
            save: "Save",
            boardingSection: "Boarding",
            addBoardingStatus: "Add boarding status",
            boardingStatuses: "Created boarding statuses",
            editDocumentType: "Edit document type",
            cancel: "Cancel",
            delete: "Delete",
            edit: "Edit",
            editBoardingStatus: "Edit boarding status",
            understand: "I understand",
            error: "Error",
            errorProjectText: "Incorrect project name.",
            errorProjectExists: "Unable to add project because it already exists.",
            errorLeaveTypeExists: "Unable to add leave type because it already exists",
            errorLeaveTypeName: "Incorrect leave type name",
            errorLeaveTypeCode: "Incorrect leave type code",

            documentTypesUpdatingMessage: "Document types updating in progress...",
            documentTypesUpdatedSuccessfully: "Document type updated successfully!",
            documentTypesUpdateFailed: "Could not update document types",
            youDoNotHavePriviledgesToThisView: "You do not have priviledges to this view",
            youDoNotHavePriviledgesToThisAction: "You do not have priviledges to this action",
        },
        userListPanel: {
            userListTitle: "User list",
            search: "Search",
            active: "Active",
            administrators: "Administrators",
            users: "Users",
            status: "Status",
            role: "Role",
            chooseColumns: "Choose columns to show",
            cancel: "Cancel",
            save: "Save",
            delete: "Delete",
            firstAndLastName: "Name",
            birthDate: "Birth date",
            dataAccess: "Data access",
            lastLogin: "Last login",
            emailStatus: "Email status",
            emailStatusNotSent: "Not sent",
            emailStatusSent: "Sent",
            emailStatusError: "Email error sending",
            emailStatusErrorConnection: "SMTP Server refused connection",
            emailStatusErrorMessage: "SMTP Server refused message",
            emailStatusErrorRecipients: "SMTP Server refused recipients",
            emailStatusErrorSender: "SMTP Server refused sender",
            emailStatusErrorInvlidResponse: "SMTP Server returned invalid response",
            emailStatusErrorCloseConn: "SMTP Server closed connection",
            emailStatusErrorException: "SMTP Server returned an unrecognized exception",
            emailStatusErrorDefault: "Unknown error",

            rowsPerPage: "Rows per page",
            of: "of",

            all: "All",
            inactive: "Inactive",
            sendingEmail: "Sending mail...",
            invalidUserIdentifier: "Incorrect user identifier",
            couldNotSendMailServerBusy: "Could not send mail - server is busy",
            couldNotSendMail: "Could not send mail",
            mailSent: "Mail sent",
            sendActivationMail: "Send activation mail",


            administrator: "Administrator",
            HRManager: "HR Manager",
            projectManager: "Project manager",
            worker: "Worker",
            lackOfData: "No data",
            activeStatus: "Active",
            inactiveStatus: "Inactive",
            youDoNotHavePriviledgesToThisView: "You do not have priviledges to this view",
            youDoNotHavePriviledgesToThisAction: "You do not have priviledges to this action",           
            editData: "Edit data",

            unblockUser: "Unblock user",
            areYouSureYouWantToUnblockUser: "Are you sure you want to unblock user {fullname} (login '{username}')?",
            blockUser: "Block user",
            areYouSureYouWantToBlockUser: "Are you sure you want to block user {fullname} (login '{username}')?",
            unblock: "Unblock",
            block: "Block",
            yes: "Yes",
            unblockingAccountInProgress: "Unblocking user...",
            blockingAccountInProgress: "Blocking user...",

            errorOccurred: "Error occurred",
            invalidRequestData: "Invalid request data",
            couldNotFindUserToUnblock: "Could not find user you wanted to unblock",
            couldNotFindUserToBlock: "Could not find user you wanted to block",
            waitAMinute: "Wait a moment",
            tooManyRequestsSent: "You sent too many requests in a short time - wait a moment and try again later",
            errorOccurredThatsAllWeKnow: "Error occurred - that's all we know",
            serverError: "An internal server error has occured. Please report this message to the administrator",
            undefinedError: "Application failed to connect to the server. Please check your internet connection",

            blocked: "Blocked",
            blockedStatus: "Blocked",

            deleteUserContent: "Are you sure to delete:",
            deleteUserTitle: "Delete user",
            couldNotFindUserToDelete: "Could not find user you wanted to delete",
            deleteUserSuccess: "User successfully deleted",

            pesel: "Pesel",
            email: "Email",
        },
        preferences: {
            title: "Preferences",
            language: "Language",
            notifications: "Notifications",
            notificationsAppCheckbox: "App notifications",
            notificationsEmailCheckbox: "Email notifications",
            notificationsMessage: "Notify about new documents:",
            notificationsHour: "Hour",
            notificationsWeekday: "Weekday",
            buttonCancel: "Close",
            buttonSave: "Save changes",

            loading: "Loading...",
            pickTime: "Pick time",
            cancel: "Cancel",
            confirm: "Confirm",
            asap: "As soon as possible",
            hourly: "Hourly",
            daily: "Daily",
            weekly: "Weekly",

            weekday: {
                monday: "Monday",
                tuesday: "Tuesday",
                wednesday: "Wednesday",
                thursday: "Thursday",
                friday: "Friday",
                saturday: "Saturday",
                sunday: "Sunday"
            },
            savingChanges: "Saving changes...",
            updatedCorrectly: "Changes saved!",
            couldNotUpdate: "Could not save provided changes",
            couldNotFetchSettingsData: "Could not fetch settings data",
            turnOnNotifications: "Turn on notifications for device",
        },
        dsConfig: {
            dsConfigTitle: "Data sharing configuration",
            noPermissions: "No permissions",
            pickedNumber: "Picked {0}",
            confirm: "Confirm",
            cancel: "Cancel",
            save: "Save",
            access: "Access",
            create: "Create",
            edit: "Edit",
            delete: "Delete",
            accept: "Accept",
            roleName: "Role name",
            roleEmpty: "Role name cannot be empty",
            lackOfPermissions: "No permissions",
            expand: "Expand",
            goBackWithoutSavingConfirmationContent: "Do you want close this window? Any provided data will not be saved"
        },
        mdlConfig: {
            mdlConfigTitle: "Modules access role configuration",
            module: "Modules",
            read: "Read",
            add: "Add",
            edit: "Change",
            delete: "Delete",
            cancel: "Cancel",
            save: "Save",
            youDoNotHavePriviledgesToThisView: "You do not have priveledges to this view",
            roleName: "Role Name",
            changesUpdated: "Changes saved!",
            noSuchRecord: "Record with given ID does not exist",
            checkAll: "Check all",
            access: "Access",
            creating: "Add",
            pageNotFound: "Page not found",
            unrecognizedError: "Unexpected error has occured",
            loading: "Loading",
            errorLoading: "An error has occured while loading page",
            noPermissions: "No permissions",
            numberPicked: "{0} picked",
            accept: "Accept",
            invalidRoleName: "This role name is already taken",
            roleEmpty: "Role name cannot be empty",
            incorrectRequest: "Incorrect request data",
            goBackWithoutSavingConfirmationContent: "Do you want close this window? Any provided data will not be saved"
        },
        permissions: {
            title: "Permissions",
            mdlRole: "Modules access roles",
            dsRole: "Data access roles",
            addGroup: "Add group",
            deleteGroup: "Delete group",
            deleteGroupContent: "Are you sure you want to delete the group?",
            groupName: "Group name",
            cancelButton: "Cancel",
            deleteButton: "Delete",
            duplicateGroup: "Duplicate group",
            duplicateGroupContent: "Are you sure you want to create a new group based on: ",
            createButton: "Create",
            headerName: "Name",
            headerUsers: "Users",
            headerActions: "Actions",
            youDoNotHavePriviledgesToThisView: "You do not have priviledges to this view",
            actionEdit: "Edit",
            actionDuplicate: "Duplicate",
            actionDelete: "Delete",
            showMore: "Show more",
            showLess: "Show less"
        },
        userDialog: {
            lastName: "Last name",
            firstName: "First name",
            email: "Email",
            phoneNumber: "Phone number",
            birthDate: "Birth date",
            pesel: "PESEL Number",
            role: "Role",
            dataAccess: "Access to data",
            login: "Login",
            loginTaken: "This login is already taken",

            headers: {
                security: "Security",
                login: "Login",
                personalData: "Personal data",
                basicData: "Basic data",
            },

            editDialogTitle: "User edit",
            addDialogTitle: "New user",

            validators: {
                lastName: {
                    lastNameRequired: "Last name is required",
                    lastNameTooLong: "Last name is too long",
                    invalidLastNameStructure: "Invalid last name structure",
                },
                firstName: {
                    firstNameRequired: "First name is required",
                    firstNameTooLong: "First name is too long",
                    invalidFirstNameStructure: "Invalid first name structure",
                },
                email: {
                    emailRequired: "Email is required",
                    invalidEmailStructure: "Invalid email structure",
                },
                role: {
                    roleRequired: "Role is required",
                },
                dataAccess: {
                    dataAccessRequired: "Information about data access required",
                },
                login: {
                    loginFieldValueRequired: "User login is required",
                },
                birthDate: {
                    birthDateRequired: "Birth date required",
                },
                phoneNumber: {
                    invalidPhoneNumberStructure: "Invalid phone number structure",
                    areaCode: "No area code entered",
                },
            },

            noWorker: "No worker",
            newWorker: "New worker",
            existingWorker: "Existing worker",
            worker: "Worker",
            sendActivationMail: "Send activation mail",
            sendingUserCreateRequest: "Creating user...",
            sendingUserUpdateRequest: "Updating user...",
            sendingUserRemoveRequest: "Removing worker...",

            errors: {
                error400: {
                    worker: {
                        ALREADY_ASSIGNED: "Worker is already assigned to another account",
                        INVALID_WORKER_CONFIG: "Invalid request for worker",
                        INVALID_WORKER_CONFIG_NO_ID: "Worker ID not provided",
                        INVALID_WORKER_ID_DOES_NOT_EXIST: "Could not find worker with provided ID",
                        INVALID_WORKER_TAKEN_OPERATION: "Could not assign user profile with existing worker - it is already assigned to other one",
                    },
                    worker_option: {
                        INVALID_WORKER_CONFIG: "Invalid request for worker",
                        INVALID_REMOVE_WORKER_OPERATION: "Could not unassign worker from user",
                        INVALID_WORKER_EXIST_OPERATION: "Could not create new worker assigned to user, because it already exists",
                        INVALID_WORKER_ASSIGNED_OPERATION: "Could not assign existing worker, because it is already assigned to other user",
                    },
                    user: {
                        USER_EXIST_IN_TENANT: "User with this full name and email already exists",
                        NEW_USERNAME: "User with this full name already exists. Use an available username: ",
                        USERNAME_TAKEN: "User (login) is already taken",
                        USER_EXIST_IN_OTHER_TENANT: "User with this full name and email already exists in other tenant"
                    }
                },
                error401: {
                    message: "Your session expired - log in and try again",
                },
                error403: {
                    message: "You do not have priviledges to providing these modifications",
                },
                error404: {
                    message: "Request receiver not found",
                },
                error429: {
                    message: "Too many request in a short time range - try again later",
                },
                anyError: {
                    message: "An error occured - that's all we know",
                }
            },

            yesNoDialogTitle: "Remove worker",
            yesNoDialogContent: "Are you sure that you want to remove worker for user {username}?",

            delete: "Delete",
            otherUserFunctionalitiesMessage: "Additional functionalities enabled for users related with worker: timesheets, leaves",
            assignWorkerMessage: "Only workers who are not related to the user account can be associated - one user can only have one worker",
            cancel: "Cancel",
            save: "Save",

            pickWorker: "Pick worker*",
            requiredField: "This field is required.",
            errorsInForm: "You have errors in your form",
            invalidLastName: "Invalid value of field 'last name'.",
            invalidFirstName: "Invalid value of field 'first name'.",
            invalidEmail: "Invalid value of field 'email'.",
            invalidPhoneNumber: "Invalid value of field 'phone number'.",
            invalidBirthDate: "Invalid value of field 'birth date'.",
            peselAssignedToOtherWorker: "Provided PESEL value is already assigned to another worker.",
            invalidPesel: "Invalid value of field 'PESEL'.",
            unrecognizedErrorInBasicDataSection: "An unrecognised error occurred in basic data section",
            requestedUserNotFound: "Requested user was not found",
            youSentTooManyRequests: "You have sent too many requests to the server. Please wait a minute before reattempting to upload the form.",
            unrecognizedErrorOcurredWhileSendingForm: "An unrecognized error has occurred when attempting to submit the form. Please try again. If this error keeps appearing, contact the administrator.",
            assigningWorkerEnabledPermissionsMessage: "Worker selection will enable additional functionalities for this user: timesheets, leaves",
            cannotDeleteWorkerUsedInApp: "You cannot remove a relation to worker because there are timesheets or leaves assigned",

            no: "No",
            yes: "Yes",
            closeUserDialogConfirmationContent: "Do you want close this window? Any provided data will not be saved",
            wait: "Wait a moment",
        },
        company: {
            title: "Company data",
            name: "Company name",
            street: "Street",
            streetNumber: "Street number",
            propertyNumber: "Property number",
            zipCode: "Zip code",
            city: "City",
            country: "Country",
            pickCountry: "Pick country",
            region: "Region",
            nip: "NIP",
            email: "E-mail",
            emailHelpText: "E-mail for sending the billings",
            phoneNumber: "Phone number",
            logoHelpText: "Preferred size 250x100 pixels (.jpg or .png format), no larger than 50 KB",
            logo: "Logo",
            address: "Company address",
            saveButton: "Save",
            cancelButton: "Cancel",
            errors: {
                name: {
                    required: "Field required",
                },
                email: {
                    required: "Field required",
                    wrong: "Incorrect email provided",
                },
                phoneNumber: {
                    wrong: "Invorrect phone number",
                    areaCode: "No area code entered",
                },
                nip: {
                    wrong: "There is already a company in the database that has this NIP number",
                    tooShort: "NIP must contains 10 characters",
                }
            },
            loading: "Loading...",
            loadingSave: "Saving...",
            couldNotFetchData: "Could not fetch data",
            youDoNotHavePriviledgesToThisView: "You do not have priviledges to this view",
        },
        removeAddressDialog: {
            cancel: "Cancel",
            delete: "Delete",
            removingAddress: "Removing address...",
            badRequest: "Bad request",
            cannotRemoveAddressInUse: "Cannot remove this address, because it is already in use",
            notFound: "Not found",
            cannotRemoveAddressDoesNotExists: "Cannot remove this address, because it does not exist",
            tooManyRequests: "Too many requests",
            youSentTooManyRequestsMessage: "You sent too many requests in a short time - wait a moment and try again later",
            anErrorOccurredTitle: "An error occurred",
            anErrorOccurredMessage: "An error occurred - that's all we know",
            deleteAddress: "Remove address",
            areYouSureYouWantRemoveAddress: "Are you sure you want remove this address?",
        },
        addressListPanel: {
            title: "Addresses",
            streetName: "Street name",
            streetNumber: "Street number",
            propertyNumber: "Property number",
            zipCode: "Zip code",
            city: "City",
            region: "Region",
            country: "Country",
            description: "Description",
            projects: "Projects",

            edit: "Edit address",
            remove: "Remove address",

            search: "Search",
            project: "Project",

            allProjectsSelected: "All",
            chooseDataTableColumns: "Choose datatable columns",
            modify: "Modify",

            badRequest: "Bad request",
            badRequestMessage: "Incorrect form was sent",
            notFound: "Not found",
            notFoundErrorMessage: "Cannot sent request - invalid url",
            errorOccurred: "An error occurred",
            errorOccurredMessage: "An error occurred while sending request - that's all we know",
            tooManyRequests: "Wait",
            tooManyRequestsMessage: "You sent too many requests in a short time - wait a moment and try again later",
            choose: "Choose",
            assignToProject: "Assign to project",
            forbiddenOperation: "Forbidden operation",
            youDoNotHavePrivilegesToDoThatOperation: "You do not have privileges to do perform that operation",

            // custom datatable footer translations
            rowsPerPage: "Rows per page",
            of: "of",
        },
        addressDialog: {
            newAddress: "New address",
            editAddress: "Edit address",

            streetName: "Street name",
            streetNumber: "Street number",
            propertyNumber: "Property number",
            zipCode: "Zip code",
            city: "City",
            region: "Region",
            country: "Country",
            description: "Description",

            cannotSendEmptyForm: "Cannot send an empty form",
            creatingNewAddress: "Creating new address...",
            updatingAddress: "Updating address...",

            cancel: "Cancel",
            save: "Save",
            badRequest: "Bad request",
            badRequestMessage: "Incorrect form was sent",
            notFound: "Not found",
            notFoundErrorMessage: "Cannot sent request - invalid url",
            errorOccurred: "An error occurred",
            errorOccurredMessage: "An error occurred while sending request - that's all we know",
            tooManyRequests: "Wait",
            tooManyRequestsMessage: "You sent too many requests in a short time - wait a moment and try again later",
            choose: "Choose",
            assignToProject: "Assign to project",
            allProjectsSelected: "All",
            forbiddenOperation: "Forbidden operation",
            youDoNotHavePrivilegesToDoThatOperation: "You do not have privileges to do perform that operation",
        },
    },
    commons: {
        buttons: {
            ok: "OK",
            save: "Save",
            confirm: "Confirm",
            continue: "Continue",
            cancel: "Cancel",
            understood: "Understood",
            upload: "Upload",
            retry: "Retry",
            close: "Close",
            export: "Export",
            delete: "Delete"
        },
        messages: {
            yes: "Yes",
            no: "No",
            cancel: "Cancel",
            logging: "Logging in...",
            loggingFailed: "Failed to log in",
            loading: "Loading...",
            savingChanges: "Saving changes...",
            processing: "Processing...",
            errorOccuredTitle: "Error",
            errorOccured: "An unknown error occured. That's all we know.",
            edit: "Edit",
            showDetails: "Show details",
            noDetails: "No details were provided",
            details: "Details",
            unassigned: "Unassigned",
            pageNotFound: "Error 404, page not found",
            tooManyRequestsErrorTitle: "Too many requests",
            tooManyRequestsError: "Server received too many reuqests from your browser. Please wait a minute before trying again",
            requestRejectedTitle: "Request rejected",
            requestRejected: "Server rejected your request. Please make sure your application is up to date (reload the website)",
            networkErrorTitle: "Network error",
            networkError: "Application failed to connect to the server. Please check your internet connection",
            tryAgainLater: "Try again later",
            requestFailedTitle: "Request failed",
            requestBoardingFailed: "Request failed for boarding id: {id}",
            requestAttachmentFailed: "Request failed for attachment: {name}",
            goBackTitle: "Unsaved changes",
            goBackReloadRequest: "Are you sure to discard changes and want to leave this page?",
            resettingPassword: "Password reset in progress...",
            forbidden: "Forbidden",
            internalServerErrorTitle: "Server error",
            internalServerError: "An internal server error has occured. Please report this message to the administrator",
            tryAgainInAMinute: "Try again in a minute",
            projectsNotChosen: "Projects not provided",
            unknownPage: "Unknown page",
            documentNotExist: "Document does not exist",
            forbiddenOperation: "Forbidden operation",
            youDoNotHavePriviledgesToThisView: "You do not have priviledges to this view",
            youDoNotHavePriviledgesToDoThatOperation: "You do not have priviledges to do that operation",
            badRequest: "Invalid request data",
            noWorkerAssigned: "No results - no worker assigned",
            dataExportedSuccesfully: "Data exported succesfully"
        },
        selectors: {
            countries: [{
                    key: "AL",
                    text: "Albania"
                },
                {
                    key: "AD",
                    text: "Andorra"
                },
                {
                    key: "AT",
                    text: "Austria"
                },
                {
                    key: "BE",
                    text: "Belgium"
                },
                {
                    key: "BY",
                    text: "Belarus"
                },
                {
                    key: "BA",
                    text: "Bosnia and Herzegovina"
                },
                {
                    key: "BG",
                    text: "Bulgaria"
                },
                {
                    key: "HR",
                    text: "Croatia"
                },
                {
                    key: "ME",
                    text: "Montenegro"
                },
                {
                    key: "CZ",
                    text: "Czech Republic"
                },
                {
                    key: "DK",
                    text: "Denmark"
                },
                {
                    key: "EE",
                    text: "Estonia"
                },
                {
                    key: "FI",
                    text: "Finland"
                },
                {
                    key: "FR",
                    text: "France"
                },
                {
                    key: "GR",
                    text: "Greece"
                },
                {
                    key: "ES",
                    text: "Spain"
                },
                {
                    key: "NL",
                    text: "Netherlands"
                },
                {
                    key: "IE",
                    text: "Ireland"
                },
                {
                    key: "IS",
                    text: "Iceland"
                },
                {
                    key: "KZ",
                    text: "Kazakhstan"
                },
                {
                    key: "LI",
                    text: "Liechtenstein"
                },
                {
                    key: "LT",
                    text: "Lithuania"
                },
                {
                    key: "LV",
                    text: "Latvia"
                },
                {
                    key: "MK",
                    text: "Macedonia"
                },
                {
                    key: "MT",
                    text: "Malta"
                },
                {
                    key: "MD",
                    text: "Moldova"
                },
                {
                    key: "MC",
                    text: "Monaco"
                },
                {
                    key: "DE",
                    text: "Germany"
                },
                {
                    key: "NO",
                    text: "Norway"
                },
                {
                    key: "PL",
                    text: "Poland"
                },
                {
                    key: "PT",
                    text: "Portugal"
                },
                {
                    key: "RU",
                    text: "Russia"
                },
                {
                    key: "RO",
                    text: "Romania"
                },
                {
                    key: "SM",
                    text: "San Marino"
                },
                {
                    key: "XS",
                    text: "Serbia"
                },
                {
                    key: "SK",
                    text: "Slovakia"
                },
                {
                    key: "SI",
                    text: "Slovenia"
                },
                {
                    key: "CH",
                    text: "Switzerland"
                },
                {
                    key: "SE",
                    text: "Sweden"
                },
                {
                    key: "TR",
                    text: "Turkey"
                },
                {
                    key: "UA",
                    text: "Ukraine"
                },
                {
                    key: "HU",
                    text: "Hungary"
                },
                {
                    key: "GB",
                    text: "United Kingdom"
                },
                {
                    key: "IT",
                    text: "Italy"
                },
                {
                    key: "XK",
                    text: "Kosovo"
                }
            ],
        }
    },
    errors: {
        projects_assignment_id: {
            code: "empty",
            string: "List cannot be empty.",
            header: "Error"
        },
        boarding: {
            off_boarding_date: {
                code: "OFF_BOARDING_DATE_LESS",
                string: "Offboarding date is lesser than onboarding date.",
                header: "Error"
            },
            other_boarding: {
                code: "OTHER_BOARDING_EXIST",
                string: "Worker is assigned to project that is planned for provided time.",
                header: "Error"
            },
            non_field_errors: {
                code: "BOARDING_CURRENT_STATUS_INVALID",
                string: "Provided boarding status cannot be handled.",
                header: "Error"
            },
            boarding_status: {
                code: "BOARDING_NEW_STATUS_INVALID",
                string: "Cannot change boarding status for provided value.",
                header: "Error"
            }
        },
        project_assignment: {
            finish_date: {
                code: "FINISH_DATE_LESS",
                string: "Project finish date is lesser than project start date.",
                header: "Error"
            },
            contract_end_date: {
                code: "CONTRACT_END_DATE_LESS",
                string: "Contract end date is lesser than contract start date.",
                header: "Error"
            },
            project_id: {
                code: "empty",
                string: "No projects selected.",
                header: "Error"
            }
        },
        user_profile: {
            pesel_unique: {
                code: "unique",
                string: "Provided PESEL number exists in the database.",
                header: "Error"
            },
            contract_end_date: {
                code: "CONTRACT_END_DATE_LESS",
                string: "Contract end date is lesser than contract start date.",
                header: "Error"
            },
            non_field_errors: {
                code: "NON_FIELD_ERROR",
                string: "Cannot update - no changes detected.",
                header: "Error"
            },
            personal_email: {
                code: "invalid",
                string: "Provided personal email address is incorrect.",
                header: "Error"
            },
            secondary_email: {
                code: "invalid",
                string: "Provided business email is incorrect.",
                header: "Error"
            },
            personal_mobile_number: {
                code: "invalid",
                string: "Provided phone number is incorrect.",
                header: "Error"
            }


        },
        worker: {
            parent_id: [{
                    code: "INCORRECT_PARENT_ID",
                    string: "Selected person is not permitted to be manager.",
                    header: "Error"
                },
                {
                    code: "THE_SAME_PARENT_ID",
                    string: "Cannot assign parent - the same was chosen.",
                    header: "Error"
                },
                {
                    code: "INVALID_PARENT_ID_TO_WORKER",
                    string: "An employee may not be subordinate to another employee who is equal to him.",
                    header: "Error"
                }

            ]
        },
        preferences: {
            non_field_errors: {
                code: "NON_FIELD_ERROR",
                string: "No data was provided.",
                header: "Error"
            },
            new_docs_notifications_time: [{
                    code: "NEW_DOCS_NOTIFICATIONS_TIME_TOO_BIG",
                    string: "Incorrect date was provided, try again later.",
                    header: "Error"
                },
                {
                    code: "NEW_DOCS_NOTIFICATIONS_TIME_NULL",
                    string: "No day or time was provided",
                    header: "Error"
                },
            ],
            new_docs_notification_phone_time: [{
                    code: "NEW_DOCS_NOTIFICATIONS_PHONE_TIME_TOO_BIG",
                    string: "Incorrect date was provided, try again later.",
                    header: "Error"
                },
                {
                    code: "NEW_DOCS_NOTIFICATIONS_PHONE_TIME_NULL",
                    string: "No day or time was provided.",
                    header: "Error"
                }
            ],
            default_error: {
                string: "An error occurred. That's all we know",
                header: "Error",
            }
        },
        permissions: {
            name: {
                string: "Group with that name already exists.",
                header: "Error",
            },
            default_error: {
                string: "An error occurred. That's all we know",
                header: "Error",
            },
            youDoNotHavePriviledgesToDoThatOperation: {
                string: "You do not have priviledges to do that operation",
                header: "Error"
            },
        },
        company: {
            email: {
                string: "Incorrect email provided.",
                header: "Error"
            },
            nip: {
                string: "There is already a company in the database that has this NIP number.",
                header: "Error"
            },
            fob_billing: {
                string: "There is already a company for billing in the database.",
                header: "Error"
            },
            default_error: {
                string: "An error occurred. That's all we know",
                header: "Error",
            },
            upload_file: [{
                    code: "FILE_TOO_LARGE",
                    string: "File too big.",
                    header: "Error"
                },
                {
                    code: "FILE_NOT_PICTURE",
                    string: "File must have a .jpg or .png extension.",
                    header: "Error",
                }
            ],
            contact_number1: {
                string: "Invorrect phone number",
                code: "Error"
            },
        }
    }
}