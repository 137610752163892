import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
// import VueRouter from 'vue-router'
import router from './routes'
import store from './store/store.js'
import i18n from './i18n'
import "@mdi/font/css/materialdesignicons.css"
import 'typeface-roboto/index.css';
import './registerServiceWorker'

Vue.config.productionTip = false

// Vue.use(VueRouter)
// Vue.use(VTooltip)

// const router = new VueRouter({
//     routes: Routes
// });

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
    components: {
        App
    },
}).$mount('#app')