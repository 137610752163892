import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        timesheetsCache: {
            page: 1,
            selectedProject: -1,
            search: null,
            itemsPerPage: 10
        },
        workersCache:{
            columnsVisible: {
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: false,
                7: true,
                8: true,
                9: true,
            },
            page: 1,
            itemsPerPage: 10,
            selectedProject: 0,
            search: null,
            status: "All",
        },
        usersCache:{
            columnsVisible: [],
            page: 1,
            itemsPerPage: 10,
            dataAccess: -1,
            search: null,
            status: -1,
        }
    },
    mutations: {
        updateTimesheetsCache(state, payload) {
            state.timesheetsCache.page = payload.page;
            state.timesheetsCache.selectedProject = payload.project;
            state.timesheetsCache.search = payload.search;
            state.timesheetsCache.itemsPerPage = payload.itemsPerPage
        },
        updateWorkersCache(state, payload) {
            state.workersCache.page = payload.page;
            state.workersCache.selectedProject = payload.project;
            state.workersCache.search = payload.search;
            state.workersCache.status = payload.status;
            state.workersCache.itemsPerPage = payload.itemsPerPage
            for(let key in payload.columnsVisible){
                state.workersCache.columnsVisible[key]=payload.columnsVisible[key]
            }
        },
        updateUsersCache(state, payload) {
            state.usersCache.page = payload.page;
            state.usersCache.dataAccess = payload.dataAccess;
            state.usersCache.search = payload.search;
            state.usersCache.status = payload.status;
            state.usersCache.itemsPerPage = payload.itemsPerPage
            state.usersCache.columnsVisible=payload.columnsVisible
            // for(let key in payload.columnsVisible){
            //     state.usersCache.columnsVisible[key]=payload.columnsVisible[key]
            // }
        },
    }
})